/* You can add global styles to this file, and also import other style files */
html,
body {
  height: auto;
  min-height: 100%;
  margin: 0;
}

.nav-fixed-top {
  padding-top: 7em;
}

.highcharts-plot-line {
  stroke-width: 2px;
}
